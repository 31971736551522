<template>
  <div class="container">
    <header>
      <img
        src="@/assets/newImg/arrow_left.png"
        width="18px"
        height="15px"
        @click="$router.go(-1)"
      />
    </header>
    <!-- 标题 -->
    <div class="title">
      <div class="t_txt">{{ $t('pay').donationPledge.toUpperCase() }}</div>
    </div>
    <!-- 捐赠协议 -->
    <div class="info">
      <div class="in_top">
        <div class="in_top_txt">
          {{ $t('pay').donationPledge.toUpperCase() }}
        </div>
      </div>
      <div class="in_content" v-html="ruleInfo"></div>
    </div>
    <!-- 按钮 -->
    <div class="btn" @click="toPay">
      {{ $t('pay').readVoluntaryDonated.toUpperCase() }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
import { getLang } from '@/utils/validate'

export default {
  components: {},
  data() {
    return {
      userInfo: {},
      ruleInfo: ''
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getDonateAgreement()
  },
  methods: {
    //捐赠协议
    async getDonateAgreement() {
      const conmentList = await userApi.getContentTitles('DonateAgreement', {
        lang: getLang()
      })
      if (conmentList.Data.length > 0) {
        const contenInfo = await userApi.getContentDetail({
          lang: getLang(),
          contentId: conmentList.Data[0].Id
        })
        this.ruleInfo = contenInfo.Data.Content
      }
    },
    toPay() {
      if (this.userInfo.TransferType == 'ToolFee')
        this.$router.push({ name: 'DonatePlatformPay' })
      else this.$router.push({ name: 'DonateUpPay' })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  background-image: url(../../assets/newImg/donate_bg.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 80px;
}
header {
  color: #fff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  .header_right {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    right: 20px;
    .txt {
      margin: 0 10px;
    }
    .img {
      margin-left: 3px;
      margin-bottom: 2px;
    }
  }
}
.title {
  text-align: center;
  font-family: PingFang SC;
  font-weight: 800;
  font-size: 15px;
  color: #ffffff;
  margin: 20px 0 30px 0;
  .t_txt {
    margin-bottom: 10px;
  }
}
.info {
  margin: 0 20px;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 5px 24px 0px rgba(73, 47, 182, 0.75);
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #492fb6;
  line-height: 15px;
  min-height: 250px;
  .in_top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    .in_top_txt {
      font-family: PingFang SC;
      font-weight: 800;
      font-size: 15px;
      color: #492fb6;
    }
  }
  .in_content {
    width: 100%;
    white-space: normal;
    word-break: break-word;
  }
}
.btn {
  margin: 25px 20px 0px 20px;
  height: 44px;
  background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
  box-shadow: 0px 2px 4px 0px rgba(173, 61, 0, 0.75);
  border-radius: 22px;
  font-family: PingFang SC;
  font-weight: 800;
  font-size: 12px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
